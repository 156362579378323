var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.invitedUsersHeaders,"items":_vm.getInvitedUser,"items-per-page":_vm.dataTableDimensions.itemsPerPage},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().name)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().email)+" ")]}},{key:"item.userRole",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().userRole)+" ")]}},{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data().createdOn))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('editAndDeleteUser',{attrs:{"userType":"invited","userIndex":item}})]}},{key:"item.inviteLink",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary","text":""},on:{"click":function($event){return _vm.copyLink(item)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-content-copy")]),_vm._v(" Copy Link ")],1),_c('input',{attrs:{"type":"hidden","id":'user' + item.id},domProps:{"value":_vm.registrationLink + item.id}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }