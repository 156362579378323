var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.registeredSuperUserHeaders,"items":_vm.getRegisteredUser,"items-per-page":_vm.dataTableDimensions.itemsPerPage},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().name)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().email)+" ")]}},{key:"item.userRole",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().userRole)+" ")]}},{key:"item.last_changed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data().last_changed))+" ")]}},{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data().createdOn))+" ")]}},{key:"item.onlineStatus",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","small":"","color":_vm.getColor(item.data().onlineStatus)}},[_vm._v(" "+_vm._s(item.data().onlineStatus)+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }