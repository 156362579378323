<template>
  <v-dialog v-model="show" max-width="30%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" elevation="0" color="white" class="primary--text" @click="newUser()">
        <v-icon left> mdi-account-plus </v-icon>
        Create New User
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="justify-center"> Create New User </v-card-title>
      <v-divider></v-divider>
      <v-progress-linear v-show="loading" indeterminate color="primary" background-opacity="0">
      </v-progress-linear>
      <v-card-text>
        <v-form ref="inviteNewUserForm" lazy-validation class="px-6">
          <v-text-field v-model="user.name" label="Full Name" :rules="rules.nameRules" required>
          </v-text-field>
          <v-text-field v-model="user.email" label="E-mail" :rules="rules.emailRules" required>
          </v-text-field>
          <v-text-field v-model="user.userRole" label="User Role" disabled>
          </v-text-field>
          <v-text-field v-model="user.clientName" label="Client" disabled>
          </v-text-field>
          <v-select v-if="role === 'General'" :items="licenses" label="Available Licenses" dense
            v-model="user.licenseNumber"></v-select>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn dark outlined color="error" class="mx-3" width="125" @click="show = !show"> Cancel </v-btn>
        <v-btn color="primary" dark @keyup.enter="inviteNewUser" @click="inviteNewUser" width="125">
          Invite User
        </v-btn>
      </v-card-actions>
      <v-divider></v-divider>
      <v-card-text align="center">
        <p>
          Invitation link sent to the user's email will be use to set the
          password and activate the account.
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import resetPasswordVue from "../../auth/resetPassword.vue";
export default {
  name: "createNewUser",
  data() {
    return {
      show: null,
      loading: false,
      role: '',
      user: {
        name: "",
        email: "",
        userRole: "",
        licenseNumber: "",
        clientName: "",
        showName: null,
        fdn: window.location.hostname,
      },
      rules: {
        nameRules: [(v) => !!v || "Full name is required"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        licenseRules: [(v) => !!v || "License is required"],
      },
    };
  },
  props: {
    userRole: String
  },
  methods: {
    inviteNewUser() {
      if (this.$refs.inviteNewUserForm.validate()) {
        this.loading = true;
        // License Verification
        if (this.userRole === 'Admin') {
          this.user.licenseNumber = 'NA'
          this.sendInvitation(this.user)
        } else {
          this.$store
            .dispatch("verifyUserLicense", this.user.licenseNumber)
            .then((response) => {
              if (response) {
                this.sendInvitation(this.user)
              } else {
                this.$store.commit("setLicenseError", true);
              }
            });
        }
      }
    },
    newUser() {
      console.log('User Role: ', this.userRole)
      this.role = this.userRole
      this.user.userRole = this.userRole
      this.user.clientName = this.selectedClient
    },
    sendInvitation(user) {
      this.$store.dispatch("inviteNewUser", user).then(
        (response) => {
          this.loading = false;
          this.show = false;
          this.$store.dispatch("showSnackBar", {
            text: response.message,
            color: "green",
          });
        },
        (error) => {
          this.$store.dispatch("showSnackBar", {
            text: error,
            color: "error",
          });
        }
      );
    }
  },
  computed: {
    ...mapGetters(["userLicenses", "selectedClient"]),
    licenses() {
      let licenses = [];
      this.userLicenses.forEach((license) => {
        if (!license.inUse) {
          licenses.push(license.licenseNumber);
        }
      });
      return licenses;
    },
  }
};
</script>