<template>
    <v-data-table :headers="invitedUsersHeaders" :items="getInvitedUser"
        :items-per-page="dataTableDimensions.itemsPerPage">
        <template v-slot:item.name="{ item }">
            {{ item.data().name }}
        </template>
        <template v-slot:item.email="{ item }">
            {{ item.data().email }}
        </template>
        <template v-slot:item.userRole="{ item }">
            {{ item.data().userRole }}
        </template>
        <template v-slot:item.createdOn="{ item }">
            {{ item.data().createdOn | formatDate }}
        </template>
        <template v-slot:item.actions="{ item }">
            <editAndDeleteUser userType="invited" :userIndex="item">
            </editAndDeleteUser>
        </template>
        <template v-slot:item.inviteLink="{ item }">
            <v-btn small color="primary" text @click="copyLink(item)">
                <v-icon left small>mdi-content-copy</v-icon>
                Copy Link
            </v-btn>
            <input type="hidden" :id="'user' + item.id" :value="registrationLink + item.id" />
        </template>
    </v-data-table>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from "moment";
export default {
    name: 'invitedUsers',
    computed: {
        ...mapGetters(['invitedUsers', 'dataTableDimensions']),
        getInvitedUser() {
            return this.invitedUsers.filter(user => user.data().userRole.toLowerCase() === 'admin')
        }
    },
    data() {
        return {
            invitedUsersHeaders: [
                { text: "Name", align: "start", value: "name" },
                { text: "Email", value: "email" },
                { text: "Roles", value: "userRole" },
                { text: "Invited On", value: "createdOn" },
                { text: "Actions", value: "actions", sortable: false },
                { text: "Invitation Link", value: "inviteLink", sortable: false },
            ],
            registrationLink: window.location.hostname + "/registration/",
        }
    },
    methods: {

        copyLink(user) {
            let link = document.querySelector("#user" + user.id);
            link.setAttribute("type", "text");
            link.select();

            if (document.execCommand("copy")) {
                this.$store.dispatch("showSnackBar", {
                    text: "Invitation link copied successfully",
                    color: "success",
                });
            } else {
                this.$store.dispatch("showSnackBar", {
                    text: "Link could not be copied.",
                    color: "error",
                });
            }
            link.setAttribute("type", "hidden");
            window.getSelection().removeAllRanges();
        },
    },
    filters: {
        formatDate(val) {
            if (!val) {
                return "-";
            }
            let date = val.toDate();
            return moment(date).fromNow();
        },
    }
}
</script>