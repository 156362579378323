<template>
    <v-row no-gutters class="fill-height">
        <v-col cols="4" class="pa-5">
            <span class="text-h4">{{ selectedClient }}</span>
        </v-col>
        <v-col cols="8" align="end" class="pa-5">
            <v-btn outlined width="125" class="mx-1" @click="currentSelection = 'users'"> Users </v-btn>
            <v-btn outlined width="125" class="mx-1" @click="currentSelection = 'shows'; pullShowData()"> Shows </v-btn>
            <v-btn outlined width="125" class="mx-1" @click="currentSelection = 'subscription'"> Subscription </v-btn>
        </v-col>
        <v-col cols="12" align="center" class="my-2">
            <v-card flat width="96%" v-if="currentSelection === 'users'">
                <v-card-title> Users <v-spacer></v-spacer>
                    <createNewUser :userRole="'Admin'"></createNewUser>
                </v-card-title>
                <v-card-text>
                    <v-tabs v-model="tab">
                        <v-tab>Registered Users</v-tab>
                        <v-tab>Invited Users</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <registered-users></registered-users>
                        </v-tab-item>
                        <v-tab-item>
                            <invited-users></invited-users>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
            </v-card>
            <v-card flat width="96%" v-if="currentSelection === 'shows'">
                <v-card-title> Shows <v-spacer></v-spacer>
                    <NewShow></NewShow>
                </v-card-title>
                <v-card-text>
                    <v-tabs v-model="tab1">
                        <v-tab>Upcoming</v-tab>
                        <v-tab>Requested</v-tab>
                        <v-tab>Completed</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab1">
                        <v-tab-item>
                            <upcoming-show></upcoming-show>
                        </v-tab-item>
                        <v-tab-item>
                            <requested-shows></requested-shows>
                        </v-tab-item>
                        <v-tab-item>
                            <completed-shows></completed-shows>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
            </v-card>
            <v-card flat width="96%" v-if="currentSelection === 'subscription'">
                <v-card-title> Subscription </v-card-title>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import createNewUser from "@/components/dialogs/user/createNewUser.vue";
import RegisteredUsers from '../../../components/dataTables/users/registeredUsers.vue';
import InvitedUsers from '../../../components/dataTables/users/invitedUsers.vue';
import NewShow from "../../../components/dialogs/shows/newShow.vue";
import upcomingShow from "../../../components/dataTables/shows/upcomingShow.vue";
import completedShows from "../../../components/dataTables/shows/completedShows.vue";
import requestedShows from "../../../components/dataTables/shows/requestedShows.vue";
import { mapGetters } from "vuex";
export default {
    name: 'clientManagement',
    components: {
        createNewUser,
        RegisteredUsers,
        InvitedUsers,
        NewShow,
        upcomingShow,
        completedShows,
        requestedShows
    },
    data() {
        return {
            tab: null,
            tab1: null,
            loading: true,
            currentSelection: 'users'
        }
    },
    computed: {
        ...mapGetters(['selectedClient'])
    },
    methods: {
        pullShowData() {
            console.log('Selected Client: ', this.selectedClient)
            this.$store.dispatch('getShows', this.selectedClient)
            this.$store.dispatch('pullKioskLicenses', this.selectedClient)
            this.$store.dispatch('pullUserLicenses', this.selectedClient)
        }
    },
    mounted() {
        this.$store.dispatch('pullUserDataSuperAdmin', this.selectedClient)
    },
}
</script>