<template>
    <v-data-table :headers="registeredSuperUserHeaders" :items="getRegisteredUser" :items-per-page="dataTableDimensions.itemsPerPage">
        <template v-slot:item.name="{ item }">
            {{ item.data().name }}
        </template>
        <template v-slot:item.email="{ item }">
            {{ item.data().email }}
        </template>
        <template v-slot:item.userRole="{ item }">
            {{ item.data().userRole }}
        </template>
        <template v-slot:item.last_changed="{ item }">
            {{ item.data().last_changed | formatDate }}
        </template>
        <template v-slot:item.createdOn="{ item }">
            {{ item.data().createdOn | formatDate }}
        </template>
        <template v-slot:item.onlineStatus="{ item }">
            <v-chip dark small :color="getColor(item.data().onlineStatus)">
                {{ item.data().onlineStatus }}
            </v-chip>
        </template>
        <!-- <template v-slot:item.actions="{ item }">
            <v-icon>mdi-edit</v-icon>
            <editAndDeleteUser userType="registered" :userIndex="item">
            </editAndDeleteUser>
        </template> -->
    </v-data-table>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from "moment";
export default {
    name: 'registeredUsers',
    data() {
        return {
            registeredSuperUserHeaders: [
                { text: "Name", align: "start", value: "name" },
                { text: "Email", value: "email" },
                { text: "Roles", value: "userRole" },
                { text: "Status", value: "onlineStatus" },
                { text: "Last Online", value: "last_changed" },
                { text: "Joined", value: "createdOn" },
                { text: "Actions", value: "actions", sortable: false },
            ]
        }
    },
    computed: {
        ...mapGetters(['selectedClient', 'registeredUsers', 'dataTableDimensions']),
        getRegisteredUser() {
            return this.registeredUsers.filter(user => user.data().userRole.toLowerCase() === 'admin')
        }
    },
    methods: {
        getColor(status) {
            if (status === "Unavailable") return "error";
            if (status === "Busy") return "amber";
            if (status === "Available") return "success";
        },
    },
    filters: {
        formatDate(val) {
            if (!val) {
                return "-";
            }
            let date = val.toDate();
            return moment(date).fromNow();
        },
    }
}
</script>